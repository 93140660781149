@media only screen and (min-width: 100px) and (max-width: 768px) {
  .asideCustom {
    width: "100%" !important;
    padding-top: "15px" !important;
    background-color: "#F4F4F4" !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  .asideCustom {
    width: "90px" !important;
    padding-top: "15px" !important;
    background-color: "#F4F4F4" !important;
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1800px) {
  .asideCustom {
    width: "900px" !important;
    padding-top: "15px" !important;
    background-color: "#F4F4F4" !important;
  }
}

@media only screen and (min-width: 1801px) {
  .asideCustom {
    width: "900px" !important;
    padding-top: "15px" !important;
    background-color: "#F4F4F4" !important;
  }
}